window.NewsAndEventPreview = (randomId) => {
	return {
		display: 'news',
		swiper: null,
		init () {
			setTimeout(() => {
				this.swiper = new Swiper(".swiper-container" + randomId, {
					loop:true,
					slidesPerView: 1,
					breakpoints: {
						830: {
							slidesPerView: 2,
						},
						1183: {
							slidesPerView: 3,
						},
						1553: {
							slidesPerView: 4,
						},
					}, 
					pagination: {
						el: ".swiper-pagination" + randomId,
						clickable: true,
						type: "bullets",
					},
					loopFillGroupWithBlank: false,
					// centeredSlides: true,
				})
			}, 0);
		},
	};
};
